.project-filters {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.project-filter {
  font-weight: 600;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.project-filter:hover {
  /* background-color: rgba(0, 0, 0, 0.1);  */
  color: #f59e0b; /* Change text color on hover */
}

.project-filter.active {
  color: #f59e0b;
}

/* bg-amber-500 */
