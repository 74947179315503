.project-card-wrapper {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.project-card {
  height: 410px;
  border: 1px solid rgba(0, 0, 0, 0.168);
  opacity: 1;
}

.project-card-wrapper.hide {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
}
.project-card-wrapper.visible {
  position: relative;
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

/* .hide .project-card {
  transform: scale(0);
  opacity: 0;
  visibility: hidden;
}

.visible .project-card {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
} */

.project-grid {
  column-gap: 50px;
  row-gap: 75px;
}

@media (max-width: 1280px) {
  .project-card {
    height: 340px;
  }
}
